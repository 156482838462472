import * as React from "react"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { navigate } from "gatsby"

import { refreshClosedStoreSettings } from "../contexts/actions"

import { StoreSettingsContext } from "../contexts"

import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/ui/layout"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "calc(100vh  - 66px)",
    position: "relative",
  },

  inBox: {
    width: "100%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
  text: {
    marginBottom: "1rem",
  },
}))

const NotFoundPage = () => {
  const { dispatchStoreSettings } = React.useContext(StoreSettingsContext)
  React.useEffect(() => {
    dispatchStoreSettings(refreshClosedStoreSettings())
  }, [dispatchStoreSettings])

  const handleClick = () => {
    navigate("/menu")
  }

  const classes = useStyles()
  return (
    <Layout footerOne={true} footerTwo={true}>
      <Box classes={{ root: classes.outBox }}>
        <Box classes={{ root: classes.inBox }}>
          <Typography variant="h2">{"404"}</Typography>

          <Typography variant="h5" classes={{ root: classes.text }}>
            {"Oops! page not found"}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            size="large"
            disableElevation
          >
            <Typography variant="body1">{"View Our Menu"}</Typography>
          </Button>
        </Box>
      </Box>
    </Layout>
  )
}
export default NotFoundPage
